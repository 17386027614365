import dibsCss from 'dibs-css';
import classnames from 'classnames';
import { type FunctionComponent } from 'react';

type FolderProps = {
    className?: string;
};

const Folder: FunctionComponent<FolderProps> = props => {
    return (
        <svg
            className={classnames(dibsCss.dibsIcon, props.className)}
            data-tn={'folder'}
            viewBox="0 0 250 250"
        >
            <path d="M237.5 65.7H129.3c-1.3 0-2.8-1.7-3.3-2.5l-.2-.3-31.2-39.3-.2-.3c-3.7-3.9-7.5-5.8-11.8-5.8H9.4C4.7 17.5-.1 21-.1 27.8v192.5c0 8 6.4 11.2 10.6 11.4h228.6c4.2-.2 7.6-2.5 9.5-6.3 1.2-2.5 1.4-4.8 1.4-5.3V78.5c0-9.4-7.5-12.8-12.5-12.8z" />
        </svg>
    );
};
export default Folder;
